<template>
  <v-container fluid>
    <div class="text-h5 mb-8">Denuncias publicadas</div>
    <v-snackbar color="accent" top v-model="snackbar" :timeout="timeout" content-class="text--center">
      <span class="d-flex justify-center"> Denúncia despublicada! </span>
      <span class="d-flex justify-center"> Denúncia voltou para análise. </span>
    </v-snackbar>
    <v-snackbar color="error" top v-model="snackbarError" :timeout="timeout" content-class="text--center">
      <span class="d-flex justify-center"> Erro ao despublicar. </span>
    </v-snackbar>
    <v-data-table :items-per-page="15" :headers="headers" :items="denunciasPublicadas" locale="pt"
      class="elevation-1">
      <template v-slot:[[`item.publishedAt`]]="{ item }">
        <span>{{ new Date(item.publishedAt).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="deleteItem(item)" color="accent">DESPUBLICAR</v-btn>
        <!-- <v-icon disabled small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>
    <v-btn style="position: absolute; bottom: 16px" color="accent" @click="$router.go(-1)">voltar</v-btn>
  </v-container>
</template>
<script>
import request from "@/router/requests.js";
export default {
  data() {
    return {
      snackbar: false,
      snackbarError: false,
      timeout: 5000,
      headers: [
        {
          text: "Codigo da denúncia",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Denunciante",
          align: "start",
          sortable: true,
          value: "users_permissions_user.username",
        },
        {
          text: "Data da publicação",
          align: "start",
          sortable: true,
          value: "publishedAt",
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    editItem(item) { },
    async deleteItem(item) {
      let denunciaAlterada = {};
      denunciaAlterada.data = Object.assign({}, item);
      let denunciaCopiada = item;
      denunciaAlterada.data.publicado = false;
      await request("PUT", `denuncias/${item.id}`, denunciaAlterada).then(
        (x) => {
          if (x.error) {
            this.snackbarError = true
            return
          }
          this.snackbar = true;
          denunciaCopiada.publicado = false;
        }
      );
    },
  },
  props: {
    denuncias: Array,
  },
  computed: {
    denunciasPublicadas() {
      return this.denuncias.filter((x) => x.publicado);
    },
  },
};
</script>